import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'home',
        path: 'home',
        component: () => import('@/views/dashboard/pages/DashboardPage')
      },
      {
        name: 'orders',
        path: 'dashboard/orders',
        component: () => import('@/views/dashboard/pages/OrdersPage')
      },
      {
        name: 'customers',
        path: 'dashboard/customer',
        component: () => import('@/views/dashboard/pages/CustomersPage')
      },
      {
        name: 'user',
        path: 'user',
        component: () => import('@/views/user/UserManagement')
      },
      {
        name: 'Search',
        path: 'dashboard/search/:search',
        component: () => import('@/views/dashboard/pages/SearchPage')
      },
      {
        name: 'machines',
        path: 'dashboard/machines',
        component: () => import('@/views/dashboard/pages/MachinesPage')
      },
      {
        name: 'Templates',
        path: 'dashboard/templates',
        component: () => import('@/views/dashboard/pages/TemplatesPage')
      },
      {
        name: 'tools',
        path: 'dashboard/tools',
        component: () => import('@/views/dashboard/pages/ToolsPage')
      },
      {
        name: 'Dashboard Login',
        path: 'dashboard/login',
        component: () => import('@/views/dashboard/LoginForm')
      },
      {
        name: 'production-plan',
        path: 'dashboard/plans/',
        component: () => import('@/views/dashboard/pages/PlansPage')
      },
      {
        name: 'warehouse',
        path: 'dashboard/warehouse',
        component: () => import('@/views/dashboard/pages/WarehousePage')
      },
      {
        name: 'materials',
        path: 'dashboard/materials',
        component: () => import('@/views/dashboard/pages/MaterialsPage')
      },
      {
        name: 'shipment',
        path: 'dashboard/shipment',
        component: () => import('@/views/dashboard/pages/ShipmentPage')
      },
      {
        name: 'Operator Login',
        path: 'operator/login',
        component: () => import('@/views/operator/OperatorLogin')
      },
      {
        name: 'operator-view',
        path: 'operator/panel',
        component: () => import('@/views/operator/pages/OperatorPanelPage')
      },
      {
        name: 'licenses',
        path: 'licenses',
        component: () => import('@/views/dashboard/pages/LicensesPage')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeResolve((to, from, next) => {
  let redirectTo = null;
  console.log('to:', to);

  if (to.path === '/') {
    redirectTo = 'home';
  }

  if (to.path === '/licenses') {
    next();
    return;
  }

  if (to.path === '/licenses.txt') {
    next();
    return;
  }

  if (!store.state.session.loggedIn) {
    console.log('not logged in');
    redirectTo = 'Operator Login';
    if (to.path.startsWith('/pages') || to.path.startsWith('/dashboard')) {
      redirectTo = 'Dashboard Login';
    }
    if (to.name == redirectTo) {
      redirectTo = null;
    }
  } else {
    const toDashboard =
      to.path.startsWith('/pages') || to.path.startsWith('/dashboard');
    console.log('toDashboard:', toDashboard);
    console.log('prefix:', store.state.session.prefix);
    if (
      toDashboard &&
      store.state.session.prefix.startsWith('/operator') &&
      to.name != 'Dashboard Login'
    ) {
      redirectTo = 'Dashboard Login';
    }
  }

  if (redirectTo) {
    next({
      name: redirectTo
    });
  } else next();
});

export default router;
