import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from './axios';
import veeValidateInit from './plugins/vee-validate';
import './plugins/base';
import './plugins/vue-the-mask';
import './plugins/idle-vue';
import './plugins/sweetalert2';
import mixin from './mixin';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.mixin(mixin);

const app = new Vue({
  router,
  store,
  vuetify,
  axios,

  beforeMount() {
    this.$store.commit('initStore');
  },

  onIdle() {
    const prefix = this.$store.state.session.prefix;
    console.log('onIdle; prefix:', prefix);

    let route = this.$route.name;
    if (route == 'Dashboard Login' || route == 'Operator Login') {
      return;
    }
    store.commit('LOGOUT');

    if (prefix?.startsWith('/dashboard/')) {
      router.push({ name: 'Dashboard Login' });
    } else {
      router.push({ name: 'Operator Login' });
    }
  },

  i18n,
  render: h => h(App)
});

veeValidateInit(app);

app.$mount('#app');
