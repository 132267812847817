import request from './request';
import i18n from '@/i18n';

export default {
  getToolTypes() {
    let url = '/api/tool_types/';

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {});
    });
  },

  appendSearch(url, opts, search, types, client_id) {
    opts ||= {};
    let qp = [];
    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }
    if (search) {
      qp.push(`search=${search}`);
    }
    let sort = [];
    if (opts.sortBy) {
      for (let i = 0; i < opts.sortBy.length; i++) {
        let sign = '';
        if (i < opts.sortDesc.length && opts.sortDesc[i]) {
          sign += '-';
        }
        let field = opts.sortBy[i];
        if (field == 'tType') {
          field = 'type';
        }
        if (field == 'tStatus') {
          field = 'status';
        }
        sort.push(sign + field);
      }
      if (sort.length > 0) {
        qp.push(`ordering=${sort.join(',')}`);
      }
    }

    if (types) {
      qp.push(`types=${types}`);
    }
    if (client_id) {
      qp.push(`client_id=${client_id}`);
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  async getTools(opts, search, types, client_id) {
    let url = `/api/tools/`;
    url = this.appendSearch(url, opts, search, types, client_id);

    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async postTool(tool) {
    delete tool['client'];
    delete tool['supplier'];
    console.log('post tool: ', tool);
    const result = await request.request(`/api/tools/`, 'post', tool, {});

    // console.log("result: ", result);
    return result;
  },

  async patchTool(tool) {
    delete tool['client'];
    delete tool['supplier'];
    console.log('patching tool: ', tool);
    let id = tool.id;
    const result = await request.request(
      `/api/tools/${id}/`,
      'patch',
      tool,
      {}
    );

    // console.log("result: ", result);
    return result;
  },

  async getParamDefinitions() {
    let url = '/api/param_definitions/tools/';
    let raw = await request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {});
    });

    let pds = {};
    raw.forEach(pd => {
      pds[pd.tool_type] = pds[pd.tool_type] || [];
      pds[pd.tool_type].push({
        ...pd,
        tparam_name: i18n.t(`tools.params.${pd.tool_type}.${pd.param_name}`)
      });
    });

    return pds;
  },

  async deleteTool(tool) {
    console.log('delete tool: ', tool);
    let id = tool.id;
    const result = await request.request(`/api/tools/${id}/`, 'delete', {}, {});

    console.log('result: ', result);
    return result;
  },

  async uploadAttachment(tool, file) {
    let url = `/api/tools/${tool.id}/upload_attachment/`;
    let req = new FormData();
    req.append('file', file);

    const rsp = await request.request(url, 'post', req);
    if (rsp['status'] !== 200) {
      console.log('Error with uploading a file:', rsp);
      throw 'Error while uploading file; status_code=' + rsp['status'];
    }
    return rsp;
  },

  async downloadAttachment(tool, path) {
    console.log('Downloading: ', path);
    let id = tool.id;
    const rsp = await request.request(
      `/api/tools/${id}/download_attachment/?path=${path}`,
      'get',
      {},
      {}
    );
    console.log(rsp);
    return rsp.data;
  },

  async downloadAttachments(tool, paths) {
    let id = tool.id;
    const url = `/api/tools/${id}/download_attachments/`;
    console.log('POST ', url);
    const req = {
      keys: paths
    };
    const rsp = await request.request(url, 'post', req);
    // console.log(rsp);
    return rsp.data;
  },

  async listAttachments(tool) {
    let url = `/api/tools/${tool.id}/attachments/`;
    console.log('url:', url);
    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async deleteAttachment(tool, attachment) {
    let url = `/api/tools/${tool.id}/attachments/${attachment.id}`;
    console.log('url:', url);
    return await request.request(url, 'delete', {}, {});
  },

  async excel(opts, search) {
    console.log('Generate tools.xlsx');
    let url = `/api/tools/excel/`;
    url = this.appendSearch(url, opts, search);

    console.log('url:', url);

    await request.download(url, 'tools.xlsx');
  }
};
