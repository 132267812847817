<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="sass">
.swal2-container
  .field
    display: flex

    .name
      font-weight: bold
      text-align: left
      min-width: 25%

    .value
      margin-left: 1em
  .detail
</style>
