<template>
  <v-menu
    v-bind="$attrs"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="currentDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on}">
      <v-text-field
        v-model="currentDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="$attrs"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="currentDate" no-title scrollable>
      <v-spacer />
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="save()">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value', 'label'],
  data: () => ({
    menu: false,
    selectedValue: ''
  }),
  computed: {
    currentDate: {
      get: function() {
        if (!this.value) return null;

        // convert to yyyy-mm-dd but in local timezone
        const day = this.value.getDate();
        const month = this.value.getMonth() + 1; // Note: month is zero-based
        const year = this.value.getFullYear();

        const ds = `${year}-${month
          .toString()
          .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        console.log('ds: ', ds);
        return ds;
      },
      set: function(newDate) {
        console.log('newDate: ', newDate);
        this.selectedValue = newDate;
      }
    }
  },
  methods: {
    save() {
      console.log('save:', this.selectedValue);
      this.emitInput(this.selectedValue);
      this.menu = false;
    },
    emitInput(newInput) {
      let d = new Date(newInput);
      d.setHours(8, 0, 0, 0);
      console.log('Emit: ', d);
      this.$emit('input', d);
    }
  }
};
</script>

<style></style>
