import Vue from 'vue';
import Vuex from 'vuex';
import ToolService from '@/services/ToolService.js';
import CustomerService from '@/services/CustomerService.js';
import i18n from '@/i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: null,
    session: {
      loggedIn: false,
      login: '',
      accessToken: '',
      exp: null,
      refreshToken: '',
      email: '',
      loggedInSince: undefined,
      user: null,
      locale: null
    },
    userVersion: 0,
    toolTypes: null,
    customerTypes: null
  },
  mutations: {
    initStore(state) {
      const s = localStorage.getItem('session');
      if (s) {
        state.session = JSON.parse(s);
        const locale = state.session?.locale;
        if (locale) {
          i18n.locale = locale;
        }
      }
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    SET_PREFIX(state, prefix) {
      state.session.prefix = prefix;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    SET_LOCALE(state, locale) {
      state.session.locale = locale;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    LOGIN(state, payload) {
      state.session.loggedIn = true;
      state.session.accessToken = payload.accessToken;
      state.session.exp = payload.exp;
      state.session.refreshToken = payload.refreshToken;
      state.session.login = payload.login;
      state.session.loggedInSince = new Date();
      state.session.user = payload.user;
      state.session.prefix = payload.prefix;
      state.userVersion++;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    SET_USER(state, user) {
      state.session.user = user;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    REFRESH(state, payload) {
      state.session.loggedIn = true;
      state.session.accessToken = payload.accessToken;
      state.session.exp = payload.exp;
      state.session.refreshToken = payload.refreshToken;
      state.session.user = payload.user;
      state.userVersion++;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    LOGOUT(state) {
      state.session.loggedIn = false;
      state.session.accessToken = undefined;
      state.session.refreshToken = undefined;
      state.session.login = undefined;
      state.session.email = undefined;
      state.session.loggedInSince = undefined;
      state.session.user = undefined;
      state.userVersion++;
      localStorage.setItem('session', JSON.stringify(state.session));
    },
    SET_TOOL_TYPES(state, toolTypes) {
      state.toolTypes = toolTypes;
    },
    SET_CUSTOMER_TYPES(state, customerTypes) {
      state.customerTypes = customerTypes;
    }
  },
  actions: {
    async getToolTypes({ commit, state }) {
      console.log('store.getToolTypes()');
      if (state.toolTypes) {
        console.log('store.getToolTypes() - from cache!');
        return state.toolTypes;
      }
      let tt = await ToolService.getToolTypes();
      commit('SET_TOOL_TYPES', tt);
      return tt;
    },
    async getCustomerTypes({ commit, state }) {
      console.log('store.getCustomerTypes()');
      if (state.customerTypes) {
        console.log('store.getCusomerTypes() - from cache!');
        return state.customerTypes;
      }
      let tt = await CustomerService.getCustomerTypes();
      commit('SET_CUSTOMER_TYPES', tt);
      return tt;
    }
  },
  modules: {}
});
