import Vue from 'vue';
import jwt_decode from 'jwt-decode';
import store from '@/store';
import request from './request';

export default {
  async login(userToken, password, pin = false) {
    if (pin) userToken = `PIN:${userToken}`;

    const loginRes = await Vue.axios.post('/auth/token/obtain/', {
      username: userToken,
      password: password
    });

    let decoded = jwt_decode(loginRes.data.access);

    const headers = {
      Authorization: 'Bearer ' + loginRes.data.access,
      'Content-Type': 'application/json'
    };
    const rsp = await Vue.axios.get(`/auth/users/current/`, {
      headers: headers
    });

    return {
      access: loginRes.data.access,
      refresh: loginRes.data.refresh,
      exp: decoded.exp,
      user: rsp.data
    };
  },

  async refresh() {
    const refreshRes = await Vue.axios.post('/auth/token/refresh/', {
      refresh: store.state.session.refreshToken
    });

    let decoded = jwt_decode(refreshRes.data.access);

    const headers = {
      Authorization: 'Bearer ' + refreshRes.data.access,
      'Content-Type': 'application/json'
    };
    const rsp = await Vue.axios.get(`/auth/users/current/`, {
      headers: headers
    });

    store.commit('REFRESH', {
      accessToken: refreshRes.data.access,
      exp: decoded.exp,
      refreshToken: refreshRes.data.refresh,
      user: rsp.data
    });
  },

  async updateProfile(profile) {
    console.log('Updating profile:', profile);
    const rsp = await request.request(
      `/auth/users/current/update_profile/`,
      'put',
      profile
    );
    console.log('updateProfile.rsp:', rsp);

    store.commit('SET_USER', rsp.data);
  }
};
