import Vue from 'vue';
import store from '@/store';
import i18n from '@/i18n';
import router from '@/router';
import Users from '@/services/UserService.js';

const refreshBefore = 60; // refresh 60s before expiration

export default {
  async refresh() {
    // check if the token is still valid
    let now = Date.now() / 1000;

    if (store.state.session.exp - now < refreshBefore) {
      try {
        await Users.refresh();
      } catch (err) {
        console.log('refresh error: ', err);
        store.commit('LOGOUT');
        router.push({ name: 'Login' });
      }
    }
  },

  async request(url, reqType, req, _headers) {
    // this mechanism has to be extended if we send request automatically/periodically
    // the timer should only be cleared in case of user triggered request.
    await this.refresh();
    const locale = i18n.locale || 'en';
    const headers = {
      Authorization: 'Bearer ' + store.state.session.accessToken,
      'Content-Type': 'application/json',
      'Accept-Language': locale
    };
    // console.log('headers:', headers);

    if (_headers) for (const key in _headers) headers[key] = _headers[key];
    try {
      let response = {};
      switch (reqType) {
        case 'post':
          response = await Vue.axios.post(encodeURI(url), req, {
            headers: headers
          });
          break;
        case 'get':
          response = await Vue.axios.get(encodeURI(url), {
            headers: headers,
            params: req || {}
          });
          break;
        case 'get_blob':
          response = await Vue.axios.get(encodeURI(url), {
            headers: headers,
            params: req || {},
            responseType: 'blob'
          });
          break;
        case 'put':
          response = await Vue.axios.put(encodeURI(url), req, {
            headers: headers
          });
          break;
        case 'patch':
          response = await Vue.axios.patch(encodeURI(url), req, {
            headers: headers
          });
          break;

        case 'delete':
          response = await Vue.axios.delete(encodeURI(url), {
            headers: headers
          });
          break;
        default:
          throw new Error('unsupported request type');
      }
      return response;
    } catch (error) {
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        throw error.response.data;
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        throw 'Network Error';
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        throw error.message;
      }
    }
  },

  async allPages(url, readPageFunc) {
    let collection = [];
    let done = false;
    while (!done) {
      const page = await readPageFunc(url);
      // console.log("page:", page);
      url = page.data.next;
      if (page.data.results) {
        collection = collection.concat(page.data.results);
      }
      if (!url || url.length == 0) done = true;
    }

    return collection;
  },

  async download(url, fileName) {
    await this.refresh();

    Vue.axios
      .post(
        encodeURI(url),
        {
          file_name: fileName
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + store.state.session.accessToken
          }
        }
      )
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
