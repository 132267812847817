import Vue from 'vue';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  localize
} from 'vee-validate';
import {
  email,
  max,
  min,
  numeric,
  required,
  regex,
  min_value,
  max_value,
  between,
  digits
} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
import de from 'vee-validate/dist/locale/de.json';
import en from 'vee-validate/dist/locale/en.json';

extend('email', email);
extend('max', max);
extend('min', min);
extend('numeric', numeric);
extend('min_value', min_value);
extend('max_value', max_value);
extend('required', required);
extend('regex', regex);
extend('between', between);
extend('digits', digits);

extend('decimal', {
  validate(value) {
    var regex = /^\d*\.?\d{0,3}$/;
    return regex.test(value);
  },
  message: 'This field must be a valid decimal number'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

export default function(app) {
  localize({
    en,
    fr,
    de
  });
  localize(app.$i18n.locale);
}
