import _ from 'lodash';

let mixin = {
  methods: {
    hasPermission(permission) {
      if (this.$store.state.session.user == null) {
        return false;
      }
      // console.log("permissions:", this.$store.state.session.user.permissions)
      return this.$store.state.session.user.permissions.includes(permission);
    },

    dateFromISO8601: function(iso) {
      if (!iso) return null;
      //2021-05-18T08:00:00.000000Z
      return new Date(iso);
    },

    operatorClass() {
      if (this.$store.state.session.prefix?.startsWith('/operator/')) {
        return 'operator';
      }
      return '';
    },

    showError(self, err) {
      const msg = this.parseErrors(err);
      this.$swal({
        title: this.$t('error'),
        html: msg,
        icon: 'error',
        confirmButtonText: this.$t('close')
      });
    },

    isString: function(x) {
      return Object.prototype.toString.call(x) === '[object String]';
    },

    parseErrors(data) {
      function _parseError(item, listPos) {
        let liT = _.template(
            '<li class="list-item"><span class="list-item-pos">Item <%= i %></span><%= content %></li>'
          ),
          ulT = _.template('<ul class="item"><%= content %></ul>'),
          output = [];

        if (_.isString(item)) {
          return item;
        }

        _.each(item, function(value, key) {
          let fieldTemplate = _.template(
              '<li class="field"><span class="name"><%- name %></span><%= content %></li>'
            ),
            vT = _.template('<span class="value"><%- value %></span>'),
            v,
            lv,
            content;

          if (_.isString(value)) {
            v = value;
          } else if (_.isArray(value)) {
            if (_.isString(value[0])) {
              v = value.join(' ');
            } else {
              lv = _fromList(value);
            }
          }

          if (v) {
            content = vT({ value: v });
          } else if (lv) {
            content = lv;
          }

          if (content) {
            if (key.search(/[A-Z]/) != -1) key = key.replace(/(?=[A-Z])/g, ' ');
            if (key.search(/[\d_]/) != -1) key = key.replace(/[\d_]/g, ' ');
            key = key.charAt(0).toUpperCase() + key.toLowerCase().slice(1);

            output.push(
              fieldTemplate({
                name: key,
                content: content
              })
            );
          }
        });

        output = output.join('');

        if (output) {
          output = ulT({ content: output });
          if (listPos) {
            output = liT({
              i: listPos,
              content: output
            });
          }
        }

        return output;
      }

      function _fromList(items) {
        let ulT = _.template('<ul class="list"><%= content %></ul>'),
          output = [];

        _.each(items, function(item, i) {
          if (!_.isEmpty(item)) {
            output.push(_parseError(item, i + 1));
          }
        });

        output = output.join('');

        if (output) {
          output = ulT({ content: output });
        }

        return output;
      }

      console.log('error:', data, typeof data);

      if (data?.message?.length) {
        console.log('error.message:', data.message);
        let ulT = _.template('<span class="message"><%= content %></span>');
        return ulT({ content: data.message });
      }
      if (data?.detail?.length) {
        console.log('error.detail:', data.detail);
        let ulT = _.template('<span class="detail"><%= content %></span>');
        return ulT({ content: data.detail });
      }
      if (_.isString(data)) {
        let ulT = _.template('<span class="message"><%= content %></span>');
        return ulT({ content: data });
      }
      if (_.isArray(data)) {
        return _fromList(data);
      } else {
        return _parseError(data);
      }
    }
  }
};

export default mixin;
