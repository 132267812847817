import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = '%BACKEND_URL%';
// Vue.axios.defaults.baseURL = 'http://127.0.0.1:8000/'

Vue.axios.interceptors.request.use(function(config) {
  // sometimes url comes from backend with full path; one example is 'next' in pagination
  // let's remove it to avoid CORS issues like below:
  // (xhr.js:187 Mixed Content: The page at 'https://pm.cclsc.ch/dashboard/shipment' was loaded over HTTPS, but requested an insecure XMLHttpRequest endpoint 'http://pm.cclsc.ch/api/parcels/?page=2&shipment_id=38'. This request has been blocked; the content must be served over HTTPS.)

  // Create a new URL object
  if (config.url.startsWith('http://') || config.url.startsWith('https://')) {
    let parsedUrl = new URL(config.url);

    // Remove the protocol, host, and optional port
    config.url = parsedUrl.pathname + parsedUrl.search;
  }
  return config;
});
