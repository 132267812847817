import request from './request';

export default {
  getCustomerTypes() {
    let url = '/api/customer_types/';

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {});
    });
  },

  async postCustomer(customer) {
    console.log('customer: ', customer);
    const result = await request.request(
      '/api/customers/',
      'post',
      customer,
      {}
    );
    console.log('result: ', result);
    return result;
  },

  getCustomers(types, search) {
    let url = `/api/customers/`;
    if (types) {
      url = `${url}?${types.map(t => `types=${t}`).join('&')}`;
    } else if (search) {
      url = `${url}?search=${search}`;
    }
    console.log('url:', url);

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {});
    });
  },

  async patchCustomer(customer) {
    console.log('customer: ', customer);
    let id = customer.id;
    const result = await request.request(
      `/api/customers/${id}/`,
      'patch',
      customer,
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async deleteCustomer(customer) {
    console.log('customer: ', customer);
    let id = customer.id;
    const result = await request.request(
      `/api/customers/${id}/`,
      'delete',
      {},
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async excel(search) {
    console.log('Generate customers.xlsx');
    let url = `/api/customers/excel/`;

    if (search?.length > 0) {
      url += `?search=${search}`;
    }
    console.log('url:', url);
    await request.download(url, 'customers.xlsx');
  }
};
