<template>
  <div>
    <v-menu
      v-model="dialog"
      :close-on-content-click="false"
      :nudge-width="200"
      max-width="290"
      offset-y
    >
      <template #activator="{ on }">
        <v-text-field
          :value="timestamp"
          :label="label"
          v-bind="$attrs"
          readonly
          :disabled="disabled"
          v-on="on"
        />
        <VMessages
          :value="errorBucket"
          color="error"
        />
      </template>
      <template #default="dialog">
        <v-card elevation="0">
          <v-tabs
            v-model="tabs"
            fixed-tabs
          >
            <v-tabs-slider />
            <v-tab
              href="#pick-date"
              class="primary--text"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-tab>

            <v-tab
              v-if="datePicker"
              href="#pick-time"
              class="primary--text"
            >
              <v-icon>mdi-clock</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="pick-date">
              <v-date-picker v-model="datePicker" />
            </v-tab-item>
            <v-tab-item value="pick-time">
              <v-time-picker
                v-model="timePicker"
                format="24hr"
                scrollable
              />
            </v-tab-item>
          </v-tabs-items>
          <v-card-text />
          <v-card-actions class="justify-end">
            <v-btn
              v-if="clearable"
              text
              @click="clear()"
            >
              Clear
            </v-btn>
            <v-btn
              text
              @click="dialog.value = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
import VInput from 'vuetify/lib/components/VInput/VInput.js'
import moment from 'moment'

export default {
  extends: VInput,
  props: {
    value: {
      required: false,
      type: [Date]
    },
    label: {
      required: false,
      type: [String]
    },
    clearable: {
      required: false,
      type: [Boolean]
    },
    disabled: {
      required: false,
      type: [Boolean]
    }
  },
  data() {
    return {
      dialog: false,
      datePicker: '',
      timePicker: '',
      tabs: null
    }
  },
  computed: {
    timestamp() {
      if (
        !this.value ||
        this.datePicker.length == 0 ||
        this.timePicker.length == 0
      ) {
        return 'not set'
      }
      return this.value.toLocaleString()
    }
  },
  watch: {
    datePicker: function(newDate, prevDate) {
      if (!prevDate.length) {
        return
      }
      this.$emit('input', this.combined())
      this.$set(this, 'tabs', 'pick-time')
    },
    timePicker: function() {
      this.$emit('input', this.combined())
    },
    clear: function() {
      this.$set(this, 'datePicker', null)
      this.$set(this, 'timePicker', null)
      this.$emit('input', null)
    }
  },
  activated() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    clear() {
      this.datePicker = ''
      this.timePicker = ''
      this.$emit('input', null)
    },
    init() {
      const time = this.value.toLocaleTimeString([], { hour12: false })
      const date = this.value.toISOString().substr(0, 10)
      this.$set(this, 'datePicker', date)
      this.$set(this, 'timePicker', time)
      this.$set(this, 'tabs', 'pick-date')
      this.$emit('input', this.combined())
    },
    combined() {
      const dt = this.datePicker + ' ' + this.timePicker
      const combined = moment(dt)
      return combined.toDate()
    }
  }
}
</script>
